<template>
  <b-modal
      no-close-on-backdrop
      size="lg"
      title="포인트 차감/지급"
      id="pointModal"
      cancel-title="취소"
      ok-title="저장"
      @ok="save"
      @cancel="reset"
      @close="reset">
    <div>
      <b-form-group v-slot="{ ariaDescribedby }" >
        <b-form-radio-group
            id="btn-radios-2"
            v-model="info.status"
            :options="[{ text: '지급', value: true },{ text: '차감', value: false }]"
            :aria-describedby="ariaDescribedby"
            button-variant="outline-primary"

            name="radio-btn-outline"
            buttons
        ></b-form-radio-group>
      </b-form-group>
    </div>
    <div class="mt-4">
      <b-form-group
          label="타이틀"
          label-for="textarea-formatter"
          description="타이틀를 입력하여 주세요."
          class="mb-0"
      >
        <b-form-input type="text" v-model="info.title"></b-form-input>
      </b-form-group>
    </div>
    <div class="mt-4">
      <b-form-group
          label="사유"
          label-for="textarea-formatter"
          description="사유를 입력하여 주세요."
          class="mb-0"
      >
        <b-form-textarea
            id="textarea-formatter"
            v-model="info.content"
            placeholder="입력하여 주세요"
            rows="3" max-rows="5"
        ></b-form-textarea>
      </b-form-group>
    </div>
    <div class="mt-4 pl-3">
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
            v-model="pointCheck"
            :options="[{text: `SamPoint`, value: 'sam'}, {text: 'BonusPoint', value: 'bonus'}]"
            :aria-describedby="ariaDescribedby"
            switches
            v-on:change="inputPointShow"
        ></b-form-checkbox-group>
      </b-form-group>
        <div class="mt-1">
            보유 SamPoint 포인트 : <b-badge variant="primary">{{numberFormat(userSamPoint)}} P</b-badge> / 보유 BonusPoint 포인트 : <b-badge variant="primary">{{numberFormat(userBonusPoint)}} P</b-badge>
        </div>
    </div>
    <div class="mt-3" v-if="info.samShow">
      <b-input-group>
        <template #prepend>
          <b-input-group-text>SamPoint</b-input-group-text>
        </template>
        <b-form-input type="number" v-model="info.samPoint"></b-form-input>
      </b-input-group>
    </div>

    <div class="mt-3" v-if="info.bonusShow">
      <b-input-group>
        <template #prepend>
          <b-input-group-text>BonusPoint</b-input-group-text>
        </template>
        <b-form-input type="number" v-model="info.bonusPoint"></b-form-input>
      </b-input-group>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "pointModal",
  props: {
    selectedId: {
      type: String,
      default: null
    },
      userSamPoint: {
          type: Number,
          default: 0,
      },
      userBonusPoint: {
          type: Number,
          default: 0,
      },
  },
  data:() => ({
    pointCheck: [],
    info: {
      status: null,
      title: null,
      content: null,
      samShow: false,
      samPoint: null,
      bonusShow: false,
      bonusPoint: null,
    }
  }),
  methods: {
    inputPointShow () {
      if (this.pointCheck.find(f => f === 'sam')) {
        this.info.samShow = true
      }else {
        this.info.samShow = false
        this.info.samPoint = null
      }

      if (this.pointCheck.find(f => f === 'bonus')) {
        this.info.bonusShow = true
      }else {
        this.info.bonusShow = false
        this.info.bonusPoint = null
      }
    },
    async save (bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.info.status === null) {
        this.$toasted.error('포인트 지급 or 차감을 선택하여 주세요.', {duration: 3000})
        return
      }

      if (this.info.title === null || this.info.title.trim() === "") {
        this.$toasted.error('타이틀를 입력하여 주세요.', {duration: 3000})
        return
      }

      if (this.info.content === null || this.info.content.trim() === "") {
        this.$toasted.error('사유를 입력하여 주세요.', {duration: 3000})
        return
      }

      if (!this.info.bonusShow && !this.info.samShow) {
        this.$toasted.error("SamPoint 또는 BonusPoint를 선택하여 주세요.", {duration: 3000})
        return
      }
      if (this.info.samShow && (this.info.samPoint === null || this.info.samPoint === "")) {
        this.$toasted.error("SamPoint를 입력하여 주세요.", {duration: 3000})
        return
      }else if (this.info.samPoint === "0") {
        this.$toasted.error("SamPoint를 0 이상 입력하여 주세요.", {duration: 3000})
        return
      }

      if (this.info.bonusShow && (this.info.bonusPoint === null || this.info.bonusPoint === "")) {
        this.$toasted.error("BonusPoint를 입력하여 주세요.", {duration: 3000})
        return
      } else if (this.info.bonusPoint === "0") {
        this.$toasted.error("BonusPoint를 0 이상 입력하여 주세요.", {duration: 3000})
        return
      }

      try {
        const {data} = await this.axios({
          url: `/admin/users/point/${this.selectedId}`,
          method: 'POST',
          data: {
            info: this.info
          }
        })

        const {result, changeUser, error} = data;
        if (!result) this.$toasted.error(error, {duration: 3000})

        const {samPoint, bonusPoint} = changeUser
        this.$toasted.success(`포인트 ${this.info.status ? '지급' : '차감' }을 성공하였습니다.`, {duration: 3000})
        this.$emit('loadList', {samPoint, bonusPoint, pointHistoryPageTF: this.$route.path.endsWith('point-history')})
        this.reset()
      } catch (e) {
        console.error(e)
      }
    },

    reset () {
      this.$nextTick(() => {
        this.$bvModal.hide('pointModal')
        this.pointCheck = []
        this.info = {
          status: null,
          title: null,
          samShow: false,
          samPoint: null,
          bonusShow: false,
          bonusPoint: null
        }
      })
    },

    numberFormat(str){
        const regexp = /\B(?=(\d{3})+(?!\d))/g;
        return String(str).replace(regexp, ',');
    }
  }
}
</script>

<style scoped>

</style>
