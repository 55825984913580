<template>
  <section class="my-4">
    <div v-if="pending">
      <b-spinner
        variant="danger"
      />
    </div>
    <div v-else>
      <div id="user-detail" class="border rounded shadow-sm p-4 bg-white d-flex flex-column justify-content-between mt-4">
        <div>
          <b-breadcrumb :items="menus" />
        </div>
        <div id="info"
             v-if="Object.values(active).filter(act => act).length"
             class="border rounded shadow-sm p-4 w-100 d-flex flex-column justify-content-start align-content-start align-items-start"
        >
          <router-view
              :selectedId="selectedId"
              ref="routerView"
          />
        </div>
        <hr/>
        <div class="flex-grow-1 d-flex flex-column justify-content-center align-content-center">
          <div class="border rounded shadow-sm p-4">
            <div class="d-flex align-content-center align-items-center justify-content-end w-100">
              <div class="flex-grow-1">
                <div class=" col-5">
                  <b-button
                      class="btn btn-success"
                      size="sm"
                      squared
                      block
                      v-b-modal.pointModal
                  >
                    포인트 지급/차감
                  </b-button>
                </div>
              </div>
              <div class="mx-1">
                <b-avatar
                    :src="user.profileImage"
                    variant="danger"
                />
              </div>
              <div class="mx-1">
                <h5>
                  {{ user.userId }}
                </h5>
                <div class="d-flex">
                  <div class="mr-2">가입일: </div>
                  <div>
                    {{ regDate }}
                  </div>
                </div>
              </div>
            </div>
            <hr/>
            <div class="text-left">
              <b-form @submit.prevent="save">

                <b-form-group
                    id="user-object-id-label"
                    label="OBJECT ID(고유키)"
                    label-for="user-object-id"
                    description=""
                >
                  <b-form-input
                      size="sm"
                      id="user-object-id"
                      :value="user._id"
                      type="text"
                      readonly
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                    id="user-use-label"
                    label="가능 여부"
                    label-for="user-use"
                    description=""
                >
                  <b-form-checkbox
                      id="user-use"
                      v-model="user.use"
                  >{{ user.use ? '가능' : '정지' }}</b-form-checkbox>
                </b-form-group>

                <b-form-group
                    id="user-email-label"
                    label="이메일"
                    label-for="user-email"
                    description="필수"
                >
                  <b-form-input
                      size="sm"
                      id="user-email"
                      v-model="user.userEmail"
                      type="email"
                      placeholder="이메일"
                      required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                    id="user-name-label"
                    label="이름"
                    label-for="user-name"
                    description=""
                >
                  <b-form-input
                      size="sm"
                      id="user-name"
                      v-model="user.userName"
                      type="text"
                      placeholder="이름"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                    id="user-nickname-label"
                    label="닉네임"
                    label-for="user-nickname"
                    description="필수"
                >
                  <b-form-input
                      size="sm"
                      id="user-nickname"
                      v-model="user.nickName"
                      type="text"
                      placeholder="닉네임"
                      required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                    id="user-phone-label"
                    label="휴대폰"
                    label-for="user-phone"
                    description="필수"
                >
                  <b-form-input
                      size="sm"
                      id="user-phone"
                      v-model="user.phone"
                      type="text"
                      placeholder="휴대폰"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                    id="user-company-label"
                    label="소속(직장)"
                    label-for="user-company"
                    description=""
                >
                  <b-form-input
                      size="sm"
                      id="user-company"
                      v-model="user.company"
                      type="text"
                      placeholder="소속(직장)(optional)"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                    id="user-profile-image-label"
                    label="프로필 이미지"
                    label-for="user-profile-image"
                    description=""
                >
                  <b-form-input
                      size="sm"
                      id="user-company"
                      v-model="user.profileImage"
                      type="text"
                      placeholder="프로필 이미지(optional)"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                    id="user-point-label"
                    label="포인트"
                    label-for="user-point"
                    description=""
                >
                  <b-form-input
                      size="sm"
                      id="user-point"
                      :value="user.samPoint"
                      type="number"
                      readonly
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                    id="user-bonus-point-label"
                    label="보너스 포인트"
                    label-for="user-point"
                    description=""
                >
                  <b-form-input
                      size="sm"
                      id="user-bonus-point"
                      :value="user.bonusPoint"
                      type="number"
                      readonly
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                    id="user-term-use-label"
                    label="이용약관 동의 여부"
                    label-for="user-term-use"
                    description=""
                >
                  <b-form-checkbox
                      id="user-term-use"
                      :checked="user.termsUse"
                      disabled
                  >{{ user.termsUse ? '동의' : '비동의' }}</b-form-checkbox>
                </b-form-group>

                <b-form-group
                    id="user-cnsprInfrm-label"
                    label="개인 정보 수집 동의 여부"
                    label-for="user-cnsprInfrm"
                    description=""
                >
                  <b-form-checkbox
                      id="user-cnsprInfrm"
                      :checked="user.cnsprInfrm"
                      disabled
                  >{{ user.cnsprInfrm ? '동의' : '비동의' }}</b-form-checkbox>
                </b-form-group>


                <b-form-group
                    id="user-newsletter-label"
                    label="뉴스레터 수신 동의 여부"
                    label-for="user-newsletter"
                    description=""
                >
                  <b-form-checkbox
                      id="user-newsletter"
                      v-model="user.newsletter"
                  >{{ user.newsletter ? '동의' : '비동의' }}</b-form-checkbox>
                </b-form-group>

                <b-form-group
                    id="user-position-label"
                    label="직위"
                    label-for="user-position"
                    description=""
                >
                  <b-form-input
                      size="sm"
                      id="user-position"
                      v-model="user.position"
                      type="text"
                      placeholder="직위(optional)"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                    id="user-task-label"
                    label="담당 업무"
                    label-for="user-task"
                    description=""
                >
                  <b-form-input
                      size="sm"
                      id="user-task"
                      v-model="user.task"
                      type="text"
                      placeholder="담당 업무(optional)"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                    id="user-recommenderTF-label"
                    label="추천인 대상 여부"
                    label-for="user-recommenderTF"
                    description=""
                >
                  <b-form-checkbox
                      id="user-recommenderTF"
                      v-model="user.recommenderTF"
                  >{{ user.recommenderTF ? '대상' : '미대상' }}</b-form-checkbox>
                </b-form-group>

                <b-form-group
                    id="user-level-label"
                    label="회원 등급"
                    label-for="user-level"
                    description=""
                >
                  <b-form-select
                      id="user-level"
                      v-model="user.level"
                      :disabled="user.level === 'member' || (user.level === 'master' && user.members > 0) || user.level === 'superAdmin'"
                  >
                    <b-form-select-option
                        v-for="i of levelOption"
                        v-bind:key="i.value"
                        :value="i.value"
                        :disabled="i.value === 'member' || i.value === 'superAdmin'">{{i.text}}</b-form-select-option>
                  </b-form-select>
                  <div v-if="user.level === 'master'">
                    {{`[초대된 멤버 회원 수: ${user.members}명 (회원 등급을 변경하고 싶으시면 멤버 회원 수가 0명이 되어야 합니다.) ]`}}
                  </div>
                </b-form-group>

                <div>
                  <b-button
                      type="submit"
                      size="sm"
                      squared
                      block
                  >
                    <font-awesome-icon icon="save"/>
                  </b-button>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pointModal :selectedId="selectedId" :userSamPoint="user.samPoint" :userBonusPoint="user.bonusPoint" @loadList="loadList"></pointModal>
  </section>
</template>

<script>

import pointModal from './pointModal'
export default {
  name: "userDetail",
  components: {
    pointModal
  },
  data: () => ({
    pending: false,
    selectedId: null,
    user: {
      userId: null,
      userEmail: null,
      nickName: null,
      phone: null,
      _id: null,
      regDate: null,
      profileImage: null,
      bonusPoint: null,
      cnsprInfrm: null,
      newsletter: null,
      position: null,
      samPoint: null,
      task: null,
      termsUse: null,
      use: null,
      recommenderTF: false,
      level: null,
      members: 0,
    },
    show: {
      payment: false,
      question: false
    },
    modalShow: false,
    levelOption: [{text: '최고관리자', value: 'superAdmin'}, {text: '관리자', value: 'admin'}, {text: '마스터', value: 'master'}, {text: '멤버', value: 'member'}, {text: '일반', value: 'general'}]
  }),
  computed: {
    regDate () {
      return this.user.regDate ? new Date(this.user.regDate).toLocaleString() : null
    },
    active () {
      return {
        point: this.$route.path.endsWith('point-history'),
        question: this.$route.path.endsWith('question-history'),
        login: this.$route.path.endsWith('login-history'),
        auth: this.$route.path.endsWith('auth-history'),
        recommender: this.$route.path.endsWith('recommender')
      }
    },
    menus () {
      return [
        {
          text: '포인트 내역',
          to: {
            path: `/users/list/${this.selectedId}/point-history`
          },
          active: this.active.point
        },
        {
          text: '문의 내역',
          to: {
            path: `/users/list/${this.selectedId}/question-history`
          },
          active: this.active.question
        },
        {
          text: '로그인 이력',
          to: {
            path: `/users/list/${this.selectedId}/login-history`
          },
          active: this.active.login
        },
        {
          text: '인증 이력',
          to: {
            path: `/users/list/${this.selectedId}/auth-history`
          },
          active: this.active.auth
        },
        {
          text: '추천 이력',
          to: {
            path: `/users/list/${this.selectedId}/recommender`
          },
          active: this.active.recommender
        }
      ]
    }
  },
  created () {
    (async () => {
      const {_id} = this.$route.params
      if (_id) {
        this.selectedId = _id
        await this.load()
      }
    })()
  },
  methods: {
    async load () {
      this.pending = true
      try {
        const { data } = await this.axios({
          url: `/admin/users/${this.selectedId}`
        })
        const { result, user } = data
        if (result && user) this.user = user
      } catch (e) {

      }
      this.pending = false
    },
    async save () {
      this.pending = true
      try {
        const { data } = await this.axios({
          url: `/admin/users/${this.selectedId}`,
          method: 'PATCH',
          data: this.user
        })

        const { result, error, user } = data
        if (!result) {
        } else {
          if (result && user) this.user = user
          this.$emit('listLoad')
        }
      } catch (e) {
      }
      this.pending = false
    },

    loadList ({samPoint, bonusPoint, pointHistoryPageTF}) {
      this.user.samPoint = samPoint
      this.user.bonusPoint = bonusPoint
      if (pointHistoryPageTF) this.$refs.routerView.load()
      this.$emit('listLoad')
    }
  }
}
</script>

<style scoped>
  #user-detail {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }
  #info {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }
</style>
